<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="6"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <!-- <label>Show</label> -->
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t("Show") }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="6" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <!-- filter model table filter propu ile eşleşir-->

                <b-form-input
                  v-model="filter"
                  class="d-inline-block mr-1"
                  :placeholder="$t('Search...')"/>

              <excelExport
                  header="SATILAN AKTİVİTELER"
                    :fields = "excel_fields"
                    :fetch  = "fetchData"
                    name="Satilan Aktiviteler"
                   :before-generate = "startDownload"
                  :before-finish   = "finishDownload">
                  <button class="btn btn-primary ml-1 mr-1" style="width:120px;"><feather-icon icon="UploadIcon"/><span class="ml-1">Excel</span> </button>
              </excelExport>
            </div>

          </b-col>
        </b-row>
        <b-row class="my-date-row">
          <b-col cols="6" md="6">
            <label for="">{{$t('Start Date Filter')}}</label>
            <flat-pickr
              v-model="start_date"
              class="form-control"
              :config="configStart"
              :placeholder="placeholder"
            @on-change="fpStartDateOnChange"
            />
          </b-col>
          <b-col cols="6" md="6">
            <label for="">{{$t('End Date Filter')}}</label>
            <flat-pickr
              v-model="end_date"
              class="form-control"
              :config="configEnd"
              :placeholder="placeholder"
              @on-change="fpEndDateOnChange"
            />
          </b-col>
        </b-row>
      </div>

      <b-table
        hover
        ref="refUserListTable"
        class="text-left position-relative"
        striped
        :per-page="perPage"
        :current-page="currentPage"
        :items="productsSoldList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        show-empty
        :empty-text="$t('No Matching Creates Found')"
        :empty-filtered-text="$t('There are no records matching your request!')"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(actions)="data">
              <template v-if="isMobile === true">
                <b-dropdown
                  variant="link"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>

                  <b-dropdown-item
                    :to="{
                      name: 'instructorEdit',
                      params: { personID: data.item.person_id },
                    }">
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">{{ $t("Edit") }}</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    :to="{
                      name: 'AddProductToTrainer',
                      params: {
                        studioID: companyBranchID,
                        trainerID: data.item.person_id,
                        trainerName: data.item.full_name,
                      },
                    }">
                    <feather-icon icon="UserIcon" />
                    <span class="align-middle ml-50">{{
                      $t("Create Product")
                    }}</span>
                  </b-dropdown-item>

                </b-dropdown>
              </template>
                  <template v-if="isMobile === false && data.item.payment_is_canceled == false">
                    <b-button
                        size="sm"
                        style="min-width:150px"
                        class=""
                        variant="danger"
                        @click="cancelPayment(data.item.customer_product_sales_id, data.index)">
                        {{ $t("Satışı İptal Et") }}
                    </b-button>
                  </template>
                  <template v-if="isMobile === false &&  data.item.payment_is_canceled == true ">
                      <b-button
                        size="sm"
                        style="min-width:150px"
                        class=""
                        variant="secondary" >
                        {{ $t("Satış İptal Edildi") }}
                    </b-button>
                  </template>
      </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm=""
            class="d-flex align-items-right justify-content-right radius 0">
            <small v-if="$i18n.locale === 'tr'" class="mt-1">
              içerisindeki {{ totalRows }} kayıttan {{ currentPage * 1 }}-{{
                currentPage * perPage}}
              arası ({{ currentPage }}.sayfa) gösteriliyor
            </small>
            <small v-if="$i18n.locale === 'en'" class="mt-1">
              showing {{ currentPage * 1 }}-{{ currentPage * perPage }} (page
              {{ currentPage }}) of {{ totalRows }} records</small>
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="6"
            sm=""
            class="
              d-flex
              align-items-right
              justify-content-right justify-content-sm-end
              radius
              0
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aling="right"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0 r-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BFormSelect,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BBreadcrumb,
  BBreadcrumbItem,
} from "bootstrap-vue";
import vSelect from "vue-select";
import DataBase from "@/services/DataBase.js";
import Ripple from "vue-ripple-directive";
import MoneyFormat from "@/functions/MoneyFormat";
import flatPickr from "vue-flatpickr-component";
import { Turkish } from "flatpickr/dist/l10n/tr.js";
import "flatpickr/dist/flatpickr.css";
import dateformat from 'dateformat';
import excelExport from '@/components/vue-json-excel/JsonExcel.vue';

export default {
  directives: {
    Ripple,
  },
  components: {
    BFormSelect,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BBreadcrumb,
    BBreadcrumbItem,

    vSelect,
    flatPickr,
    excelExport
  },

  data() {
    return {
      placeholder: "dd.mm.YYYY",
      currentPage: 1,
      totalRows: 1,
      isSortDirDesc: false,
      sortDesc: false,
      sortBy: "id",
      filter: null,
      perPageOptions: [10, 25, 50, 100, 500],
      perPage: 25,
      filterOn: [],

      locale: this.$i18n.locale,

      tableColumns: [
        { key: "customer_product_sales_id", label: this.$t("ID") ,  thClass: 'd-none',tdClass: 'd-none'},
        { key: "title", label: this.$t("Product Title") },
        { key: "created_date", label: this.$t("PURCHASE DATE") },
        { key: "expiry_date" , label: this.$t("VALIDITY DATE")},
        { key: "total_amount_after_tax_formatted",label: this.$t("Total Amount")},
        { key: "identification_number", label: this.$t("Identity No") },
        { key: "full_name", label: this.$t("Customer Name") },
        { key: "email", label: this.$t("Email") },
        { key: "phone_number", label: this.$t("Phone Number") },
        { key: "actions", label: this.$t("TRANSACTIONS")}
      ],
      excel_fields: {
            'AKTİVİTE ADI': 'title',
            'OLUŞTURMA TARİHİ' :'created_date',
            'GEÇERLİLİK TARİHİ' :'expiry_date',
            'TOPLAM TUTAR': 'total_amount_after_tax_formatted',
            'KİMLİK NO': 'identification_number',
            'ADI SOYADI' :'full_name',
            'EMAİL' :'email',
            'TELEFON': 'phone_number',
            'ŞEHİR':'city_name',
            'İLÇE':'town_name',
            'ADRES':'address',
            'DURUM': 'payment_is_canceled',
        },

        json_fields: {
        'Complete name': 'name',
        'Date': 'date',
      },

      companyBranchID: null,

      productsSoldList: [],
      productsSoldBaseList: [],
      productsForExportExcelList:[], //Excel export için

      start_date: '12.12.2021',
      end_date: '12.12.2021',

      configStart: {
        dateFormat: "d.m.Y",
        locale: Turkish,
        minDate: "01.01.1940",
        maxDate: "today"
      },

      configEnd: {
        dateFormat: "d.m.Y",
        locale: Turkish,
       // maxDate: "today",
        minDate: "01.01.1940",
      },
    };
  },
  watch: {
    productsSoldList(val) {
      this.totalRows = val.length;
    },

    start_date(val)
    {

      //  this.configEnd.minDate = val; //bitiş tarihininin başlangıç değeri en az başlangıç tarihi değerine eşit olabilir
      //  var prmStartDate= this.$functions.ConvertToEnglishDateFormat(val);
      //  var prmEndDate=this.$functions.ConvertToEnglishDateFormat(this.end_date);
    },
    end_date(val){

      // this.configStart.maxDate = val;
      // var prmStartDate= this.$functions.ConvertToEnglishDateFormat(this.start_date);
      // var prmEndDate=this.$functions.ConvertToEnglishDateFormat(val);
    },


    //new Date('01.01.2020') < new Date('01.02.2020')
    // start_date(val)
    // {
    //   this.productsSoldList = []
    //   this.configEnd.minDate = val; //bitiş tarihininin başlangıç değeri en az başlangıç tarihi değerine eşit olabilir
    //   this.productsSoldBaseList.map(el => {

    //     var prmStartDate= this.$functions.ConvertToEnglishDateFormat(val);

    //     var prmTickStartDate= this.moment(prmStartDate).format('X')
    //     //console.log("Start Date => "+ prmStartDate + " Span Start Date"  + prmTickStartDate)
    //     var prmEndDate=this.$functions.ConvertToEnglishDateFormat(this.end_date);

    //     var prmTickEndDate= this.moment(prmEndDate).format('X')
    //     //console.log("End Date => "+ prmEndDate +" Time Span End Date "+prmTickEndDate );

    //     var rowDate=  this.$functions.ConvertToEnglishDateFormat(el.created_date);

    //     var rowTSpanDate = this.moment(rowDate).format('X')
    //     //console.log("Columns Date => "+ rowDate+" Time Span Row Date " + rowTSpanDate)

    //      if(rowTSpanDate >= prmTickStartDate &&  rowTSpanDate <= prmTickEndDate )
    //      {
    //          this.productsSoldList.push(el)
    //      }
    //   })
    // },
    // end_date(val){
    //   this.configStart.maxDate = val; //başlangıç tarihinin son değeri en çok bitiş tarihi değerine eşit olabilir
    //   this.productsSoldBaseList.map(el =>
    //   {
    //     var prmStartDate= this.$functions.ConvertToEnglishDateFormat(this.start_date);

    //     var prmTickStartDate= this.moment(prmStartDate).format('X')
    //     //console.log("Start Date => "+ prmStartDate + " Span Start Date"  + prmTickStartDate)
    //     var prmEndDate=this.$functions.ConvertToEnglishDateFormat(val);
    //     var prmTickEndDate= this.moment(prmEndDate).format('X')
    //     //console.log("End Date => "+ prmEndDate +" Time Span End Date "+prmTickEndDate );
    //     var rowDate=  this.$functions.ConvertToEnglishDateFormat(el.created_date);

    //    var rowTSpanDate = this.moment(rowDate).format('X')

    //      if(rowTSpanDate >= prmTickStartDate &&  rowTSpanDate <= prmTickEndDate )
    //      {
    //          this.productsSoldList.push(el)
    //      }
    //   })
    // }
  },

  mounted() {




    // Set the initial number of items
    this.totalRows = this.personData?.length;
  },




  methods: {

      fpStartDateOnChange(selectedDates, dateStr, instance)
      {
        this.configEnd.minDate = dateStr; //bitiş tarihininin başlangıç değeri en az başlangıç tarihi değerine eşit olabilir
        var prmStartDate= this.$functions.ConvertToEnglishDateFormat(dateStr);
        var prmEndDate=this.$functions.ConvertToEnglishDateFormat(this.end_date);
      //  console.log({"StartDate":prmStartDate},{"EndDate":prmEndDate})
        this.getProductsSoldList(this.companyBranchID,prmStartDate,prmEndDate)

      },

      fpEndDateOnChange(selectedDates, dateStr, instance)
      {


        this.configStart.maxDate = dateStr;
        var prmStartDate= this.$functions.ConvertToEnglishDateFormat(this.start_date);
        var prmEndDate=this.$functions.ConvertToEnglishDateFormat(dateStr);

        console.log({"StartDate":prmStartDate},{"EndDate":prmEndDate})


        this.getProductsSoldList(this.companyBranchID,prmStartDate,prmEndDate)

      },

    async fetchData()
    {
      var prmEndDate=this.$functions.ConvertToEnglishDateFormat(this.end_date);
      var prmStartDate= this.$functions.ConvertToEnglishDateFormat(this.start_date);

      console.log({"StartDate":prmStartDate},{"EndDate":prmEndDate});

      await  this.$database.ProductService.get_company_sales_products_for_excel(
        this.companyBranchID,prmStartDate,prmEndDate
      ).then((res) => {
        if (res.is_success === true || res.is_success === 1) {

          this.productsForExportExcelList=[];
          res.result.map((el) => {
              let productsSold={};
              productsSold.activity_color=el.activity_color
              productsSold.activity_name=el.activity_name
              productsSold.created_date=this.moment(el.created_date).locale(this.locale).format('L'),
              productsSold.customer_product_sales_id=el.customer_product_sales_id
         //   productsSold.description=el.description
          //  productsSold.discount_rate=el.discount_rate
              productsSold.total_amount_after_tax_formatted=el.discounted_total_amount
              productsSold.email=el.email
              productsSold.expiry_date=el.expiry_date == null ?"Kısıtlama yok": this.moment(el.expiry_date).locale(this.locale).format('L')
            //  productsSold.last_name=el.last_name
              productsSold.full_name=el.name+" "+ el.last_name
              productsSold.payment_is_canceled= el.payment_is_canceled == true ? "Satış iptal edildi":"Satış gerçekleşti"
              productsSold.phone_number=el.phone_number
            //  productsSold.quantity=el.quantity
            //  productsSold.tax_rate=el.tax_rate
             productsSold.title=el.title
             productsSold.total_amount_after_tax=el.total_amount_after_tax
             productsSold.city_name=el.city_name
             productsSold.town_name=el.town_name
             productsSold.address=el.address
            //  productsSold.total_price=el.total_price
            //  productsSold.unit_price=el.unit_price
            //  productsSold.updated_date=el.updated_date
            productsSold.identification_number = el.identification_number
              this.productsForExportExcelList.push(productsSold);
          });
        }
      });
        return await this.productsForExportExcelList;
    },
    startDownload(){

    },


    finishDownload(){

    },

    getProductsSoldList(companyBranchID,start_date,end_date)
     {
          this.productsSoldBaseList= [];
          this.productsSoldList=[];

      this.$database.ProductService.get_company_sales_products_for_excel(companyBranchID,start_date,end_date
      // this.companyBranchID,this.start_date, this.end_date
      // this.$database.ProductService.get_company_sales_products(
      //   this.companyBranchID
      ).then((res) => {
        if (res.is_success === true || res.is_success === 1) {
            res.result.map((el) => {
              let productsSold={};
              productsSold.activity_color=el.activity_color
              productsSold.activity_name=el.activity_name
              productsSold.created_date=this.moment(el.created_date).locale(this.locale).format('L'),
              productsSold.customer_product_sales_id=el.customer_product_sales_id
         //   productsSold.description=el.description
          //  productsSold.discount_rate=el.discount_rate
              productsSold.total_amount_after_tax_formatted=el.discounted_total_amount
              productsSold.email=el.email
              productsSold.expiry_date=el.expiry_date == null ?"Kısıtlama yok": this.moment(el.expiry_date).locale(this.locale).format('L')
            //  productsSold.last_name=el.last_name
              productsSold.full_name=el.name+" "+ el.last_name
              productsSold.payment_is_canceled=el.payment_is_canceled
              productsSold.phone_number=el.phone_number
            //  productsSold.quantity=el.quantity
            //  productsSold.tax_rate=el.tax_rate
             productsSold.title=el.title
             productsSold.total_amount_after_tax=el.total_amount_after_tax
            //  productsSold.total_price=el.total_price
            //  productsSold.unit_price=el.unit_price
            //  productsSold.updated_date=el.updated_date
            productsSold.identification_number = el.identification_number
              this.productsSoldBaseList.push(productsSold);
          });
        }



        this.productsSoldList = this.productsSoldBaseList;
      });
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    dateFormat: (val) => {
      let sonucDeger;
      if (typeof val !== "undefined") {
        let date = val.split("T")[0].split("-").reverse().join(".");
        let hour = val.split("T")[1].split("+")[0];
        let hourPart = hour.split(":");
        hour =
          hourPart[0] + ":" + hourPart[1] + ":" + hourPart[2].split(".")[0];
        sonucDeger = date + " " + hour;
      } else {
        sonucDeger = "Bilgi mevcut değil";
      }
      return sonucDeger;
    },

    cancelPayment(val,index) {
      this.$swal({
        title: this.$t("Are you sure?"),
        text: this.$t("Siparişi iptal etmek istediğinizden emin misiniz ?"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("Yes"),
        cancelButtonText: this.$t("No"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {

         if (result.isConfirmed == true) {

            this.$database.CustomerProductSaleService.CancelProductSale(val,).then(
              (res) => {
                // console.log(res);
                if (!res.IsSuccess) {
                  this.$functions.Messages.error("Action failed!");
                  // this.productsSoldList[index].payment_is_canceled= true;
                } else {
                  this.$functions.Messages.success(res.Result);

                  this.productData= [];
                  this.productBaseData = [];
                  this.getProductList();
                  this.productsSoldList[index].payment_is_canceled= true;
                }
              }
            );
        }
      });
    },
  },
  created() {
    // this.$route.meta.breadcrumb[0].title = 'newTitle'
    this.companyBranchID = this.$store.getters["auth/userInfo"].company_branch_id;

    this.start_date =this.moment().add(-1,'month').locale('tr').format("L");

    this.end_date =this.moment().add(1,'month').locale('tr').format("L");

    var prmEndDate=this.$functions.ConvertToEnglishDateFormat(this.end_date);
    var prmStrarDate=this.$functions.ConvertToEnglishDateFormat(this.start_date);
    this.getProductsSoldList(this.companyBranchID,prmStrarDate,prmEndDate);
  },
};
</script>

<!-- stillendirme -->
<style lang="scss" src="@/styles/scss/productsSold.scss" scoped></style>
